.hero-section {
    background: linear-gradient(180deg, #EEE9FF 0%, #F7E5FE 100%);
    height: 100%;
}

.hero-section-container {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    margin-inline: auto;
    padding: 0 80px;

}

.hero-section-container .image-block {
    position: relative;
    width: 50%;
}

.hero-section-container .image-block img {
    width: 619px;
    max-height: 628px;
    object-fit: contain;
    bottom: 0;
    left: 0;
    position: absolute;
}

.hero-section-container .content-block {
    display: flex;
    width: 50%;
    max-width: 677px;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
}

.hero-section-container .content-block h2 {
    font-size: 53px;
    font-weight: 700;
    line-height: 64px;

}

.hero-section-container .content-block .des {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-right: 50px;
}

.hero-section-container .button-block {
    display: flex;
    gap: 10px;
}

.hero-section-container .button-block img {
    height: 36px;
    width: 104px;
}

@keyframes bounce {
    0% {
        margin-bottom: 0;
    }
    50% {
        margin-bottom: 20px;
    }
    100% {
        margin-bottom: 0;
    }
}

.hero-section .scroll-down {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    animation: bounce 1.5s infinite;
    cursor: pointer;
}

.hero-section .scroll-down a {
    display: block;
}

.hero-section .scroll-down img {
    width: 35px;
    height: 35px;
}


@media screen and (max-width:600px) {
    .hero-section-container {
        padding-top: 30px;
        flex-direction: column-reverse;
        gap: 20px;
        align-items: center;
        justify-content: flex-end;
    }

    .hero-section-container .image-block {
        width: 100%;
        height: 280px;
    }

    .hero-section-container .image-block img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: static;
    }

    .hero-section-container .content-block {
        width: 100%;
        margin-bottom: 10px;
    }

    .hero-section-container .content-block h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .hero-section-container .content-block .des {
        margin-right: 0px;
    }

    .hero-section-container .button-block img {
        height: 36px;
        width: 104px;
    }

    .hero-section .scroll-down {
        bottom: 38px;
    }

    .hero-section-container .button-block {
        /* display: none; */
    }

    .hero-section .scroll-down {
        display: none;
    }
}