@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Inter;
}
.fp-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.fp-is-overflow>.fp-overflow {
  overflow-y: auto;
  height: 100%;
}
.section{
  min-height:100vh ;
  padding-top: 70px;
}