.footer-section {
    background: linear-gradient(.25turn, #ac9dde, #F7E6FF, #C15EE499);
    width: 100vw;
    height: 100%;
}

.footer-section-container {
    max-width: 1440px;
    height: 100%;
    margin-inline: auto;
    display: flex;
    justify-content: center;
    align-items: center;

}

.footer-section-container .footer-content {
    width: 100%;
    height: 261px;
    max-width: 496px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-section-container .company-name {
    font-size: 34px;
    font-weight: 700;
    line-height: 41px;
    text-align: center;

}

.footer-section-container .link-container {
    display: flex;
    gap: 24px;
    align-items: center;
    margin: 37px 0px 42px;
}

.footer-section-container .link-container .divider {
    height: 17px;
    width: 1px;
    border: 1px solid #9A7EFF;
}

.footer-section-container .link{
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #555555;
    text-decoration: none;
    cursor: pointer;
    width: max-content;
    white-space: nowrap;
}
.footer-section-container .pattern img{
    width: 153px;
    height: 13px;    
}
.footer-section-container .social-links{
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 37px;
    margin-bottom: 22px;
}

.footer-section-container .social-links img {
    height: 28px;
    width: 28px;
}

.footer-section-container .all-right-reserved {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #3A3A3A;
}
@media screen and (max-width: 600px) {
    .footer-section-container {
        align-items: start;
    }
    .footer-section-container .footer-content {
        padding: 40px;
        align-items: start;
    }
    .footer-section-container .company-name {
        font-size: 24px;
        line-height: 30px;
    
    }
    .footer-section-container .link-container{
        flex-direction: column;
        align-items: start;
    }
    .footer-section-container .link-container .divider{
        display: none;
    }
  
    .footer-section-container .link{
        font-size: 16px;
        line-height: 20px;
      
    }

  }