.feature-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin-inline: auto;
  padding: 55px 100px;
}

.feature-container .image-block img {
  width: 100%;
  height: 100%;
  max-width: 572px;
  max-height: 480px;
  object-fit: contain;
}

.feature-container .image-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-container .content-block {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  max-width: 572px;
  gap:20px;
  margin-top: -20px;
}


.feature-container .content-block h2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 62px;
  color: #090914;
}

.feature-container .content-block p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #18181B;
}

.feature-container.left {
  flex-direction: row;
}

.feature-container.right {
  flex-direction: row-reverse;
}

@media screen and (max-width:600px) {
  .feature-container {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding: 0 40px;
  }

  .feature-container.left {
    flex-direction: column;
  }

  .feature-container.right {
    flex-direction: column;
  }
  .feature-container .image-block img {
    width: 230px;
    height: 330px;
  }


  .feature-container .content-block h2 {
    font-size: 24px;
    line-height: 30px;
  }
}