:root {
    --primary-blue: #8766FF;
}

.nav {
    width: 100vw;
    height: 70px;
    background-color: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000 !important;
    box-shadow: 0px 4px 14px 0px #0000000D;
}

.nav-container {
    max-width: 1440px;
    height: 100%;
    margin-inline: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 80px;
}

.logo a {
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 24px;
    color: var(--primary-blue);
}

.nav-links {
    display: none;
    gap: 24px;
    list-style-type: none;
}

.nav-links li a {
    height: 70px;
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    line-height: 19px;
}

.nav-links li a:hover {
    color: var(--primary-blue);

}

.nav-links li a.active {
    font-weight: 600;
    color: var(--primary-blue);
    border-bottom: 2px solid var(--primary-blue);
}

.nav-container .menu img {
    height: 30px;
    width: 30px;
    display: none;
}


@media screen and (max-width:600px) {
    .nav-container {
        padding: 0px 20px;
    }

    .nav-links {
        background: white;
        flex-direction: column;
        width: 100vw;
        min-height: calc(100vh - 70px);
        position: absolute;
        align-items: start;
        top: 70px;
        left: 0;
        padding: 10px 20px;
        border-top: 1px solid rgb(245, 239, 239);
    }

    .nav-container .menu img {
        display: none;
    }

    .nav-container .hide {
        display: none;
    }

    .nav-links li a {
        height: auto;
    }

    .nav-links li a.active {
        color: var(--primary-blue);
    border-bottom: none;

    }
}